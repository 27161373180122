<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{$t('Filtrar por')}}:
                        v-select.mb-3(
                            v-model="filter.lang"
                            :items='langsArr'
                            :label='$t("Traducción pendiente")' 
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            :filter="vFilter"
                            item-value="id"
                            dark
                            color="white"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="saveColor"
                v-model="save")
                    | {{ saveText }}
                    v-btn(flat, dark, @click.native="save = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h2 {{$t('Traducciones')}}
                    v-divider
                    v-tabs(
                        v-model="tab"
                        show-arrows
                    )
                        v-tabs-slider
                        v-tab(href="#tab-1") {{ $t('Interfaz')}}
                        v-tab(href="#tab-2") {{ $t('Aplicación')}}
                    v-tabs-items(v-model="tab")
                        v-tab-item.my-2(id="tab-1" lazy)
                            template(v-if="tab == 'tab-1'")
                                v-layout(row wrap align-end)
                                    v-flex(xs8)
                                        v-text-field(clearable :label="$t('Nueva traducción')" v-model="keyUi")
                                    v-flex(xs4)
                                        v-btn(@click="addKey(1)" color="primary" small :loading="loadUi" :disabled="loadUi") {{ $t('Agregar') }}
                                template(v-if="preload")
                                    h3 {{ $t('Cargando') }}...
                                template(v-else-if="translation.length")
                                    v-expansion-panel(inset focusable ref="panel")
                                        v-expansion-panel-content(v-for="(key, i) in translation" :key="'ui-'+i" lazy)
                                            div(slot="header") 
                                                span.d-block {{ key.key }}
                                                small(v-if="Object.values(key.values).some((v) => !v.trim().length)") {{ $t('Traducción pendiente') }}: {{ pendingLang(key.values) }}
                                            v-card.pa-3
                                                v-layout(row wrap)
                                                    v-flex(xs12)
                                                        v-text-field(clearable :value="key.key" @change="key.key = $event" :label="$t('Original')")
                                                    v-flex(xs12 v-for="(lang, i) in Object.keys(key.values)" :key="'l'+i")
                                                        v-text-field(clearable :value="key.values[lang]" @change="key.values[lang] = $event" :label="languages[lang]")
                                                    v-flex(xs12)
                                                        .btns.btns-right
                                                            v-btn(color="secondary" small :loading="key.loadDel" :disabled="key.loadDel" @click="deleteTranslate(key, i, 1)") {{ $t('Eliminar') }}
                                                            v-btn(color="primary" small :loading="key.load" :disabled="key.load" @click="saveTranslate(key)") {{ $t('Guardar') }}
                                    infinite-loading(:infinite="infinite" ref="infinite")
                                .not-found.text-xs-center.my-2(v-else)
                                    h3 {{ $t('No se encontraron traducciones') }}
                        v-tab-item(id="tab-2")
                            template(v-if="tab == 'tab-2'")
                                v-layout(row wrap end)
                                    v-flex(xs8)
                                        v-text-field(clearable :label="$t('Nueva traducción')" v-model="keyApp")
                                    v-flex(xs4)
                                        v-btn(@click="addKey(2)" color="primary" small :loading="loadApp" :disabled="loadApp") {{ $t('Agregar') }}
                                template(v-if="preload")
                                    h3 {{ $t('Cargando') }}...
                                template(v-else-if="translation.length")
                                    v-expansion-panel(inset focusable ref="panel")
                                        v-expansion-panel-content(v-for="(key, i) in translation" :key="'app-'+i")
                                            div(slot="header") 
                                                span.d-block {{ key.key }}
                                                small(v-if="Object.values(key.values).some((v) => !v.trim().length)") {{ $t('Traducción pendiente') }}: {{ pendingLang(key.values) }}
                                            v-card.pa-3
                                                v-layout(row wrap)
                                                    v-flex(xs12)
                                                        v-text-field(clearable :value="key.key" @change="key.key = $event" :label="$t('Original')")
                                                    v-flex(xs12 v-for="(lang, i) in Object.keys(key.values)" :key="'l'+i")
                                                        v-text-field(clearable :value="key.values[lang]" @change="key.values[lang] = $event" :label="languages[lang]")
                                                    v-flex(xs12)
                                                        .btns.btns-right
                                                            v-btn(color="secondary" small :loading="key.loadDel" :disabled="key.loadDel" @click="deleteTranslate(key, i, 2)") {{ $t('Eliminar') }}
                                                            v-btn(color="primary" small :loading="key.load" :disabled="key.load" @click="saveTranslate(key)") {{ $t('Guardar') }}
                                    infinite-loading(:infinite="infinite" ref="infinite")
                                .not-found.text-xs-center.my-2(v-else)
                                    h3 {{ $t('No se encontraron traducciones') }}
</template>

<script>

    import auth from 'mixins/auth'
    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Traducciones')
            }
        },
        data(){
            return {
                tab                  : 'tab-1',
                loadingBtn           : false,
                translation          : [],
                save                 : false,
                saveColor            : 'error',
                saveText             : '',
                key                  : '',
                keyUi                : '',
                keyApp               : '',
                languages: {
                    pt: 'Portugués',
                    en: 'Inglés',
                    it: 'Italiano',
                    fr: 'Francés',
                    de: 'Alemán',
                },
                loadUi: false,
                loadApp: false,
                limit       : this.$root.limit,
                offset      : 0,
                filterSchema: {
                    query   : '',
                    lang    : ''
                },
                filter      : {},
                preload     : false,
                drawerRight : false,
                type        : ''
            }
        },
        watch: {
            tab: {
                handler(){

                    this.filter  = this._.cloneDeep(this.filterSchema)
                    this.limit   = this.$root.limit
                    this.offset  = 0
                    this.loadTab = false

                    // UI
                    if(this.tab == 'tab-1'){

                        this.type = 1
                        this.fetch()
                    }

                    // APP
                    if(this.tab == 'tab-2'){

                        this.type = 2
                        this.fetch()
                    }
                },
                immediate: true
            },
            filter: {
                handler(){
                    this.fetch()
                },
                deep: true
            }
        },
        methods: {

            cleanFilter(){

                this.translation = []
                this.filter = this._.cloneDeep(this.filterSchema)
            },

            pendingLang(values){
                
                let pending = []
                for(let lang in values){

                    let value = values[lang]
                    if(!value.trim().length){
                        pending.push(this.languages[lang])
                    }
                }

                return pending.join(', ')
            },

            fetch(infinite = false){

                if(infinite){
                    this.offset += this.limit
                }else{
                    this.preload = true
                    this.translation = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                let filter = {
                    limit : this.limit,
                    offset: this.offset,
                    type  : this.type,
                    ...this.filter
                }

                this.$api(this, (xhr) => {
                    xhr.get('/i18n', { params: filter }).then((r) => {

                        if(!infinite) this.preload = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.translation = this.translation.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.translation = (data.response ? data.data : [])
                        }
                    }).catch(() => {})
                })
            },

            infinite(){
                setTimeout(() => this.fetch(true) , 500)
            },

            addKey(env){

                if(env == 1 ? !this.keyUi.trim().length : !this.keyApp.trim().length){

                    this.saveColor = 'error'
                    this.saveText  = this.$t("Por favor inserte una traducción")
                    this.save = true
                    return
                }

                (env == 1 ? this.loadUi = true : this.loadApp = true)

                let post = {
                    key: env == 1 ? this.keyUi : this.keyApp,
                    env
                }

                this.$api(this, (xhr) => {
                    xhr.post('/i18n', this.$qs.stringify(post)).then((r) => {

                        (env == 1 ? this.loadUi = false : this.loadApp = false)

                        let data = r.data
                        this.save = true

                        if(data.response){
                            
                            this.saveColor = 'success'
                            this.saveText = data.message

                            this.fetch()
                            (env == 1 ? this.keyUi = '' : this.keyApp = '')

                        }else{
                            this.saveColor = 'error'
                            this.saveText = data.message
                        }

                    }).catch(() => {
                        (env == 1 ? this.loadUi = false : this.loadApp = false)
                    })
                })
            },

            saveTranslate(key){

                key.load = true
                let post = this._.cloneDeep(key)
                post.values = JSON.stringify(post.values)

                this.$api(this, (xhr) => {
                    xhr.post('/i18n/translate', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        key.load = false
                        this.save = true

                        if(data.response){
                            this.saveColor = 'success'
                            this.saveText = data.message
                        }else{
                            this.saveColor = 'error'
                            this.saveText = data.message
                        }
                    }).catch(() => {
                        key.load = false 
                    })
                })
            },

            deleteTranslate(key, i, env){

                key.loadDel = true
                this.$api(this, (xhr) => {
                    xhr.delete('/i18n?uuid='+key.uuid).then((r) => {

                        let data = r.data
                        key.loadDel = false

                        this.save = true

                        if(data.response){
                            
                            this.saveColor = 'success'
                            this.saveText = data.message

                            let items = this.$refs.panel.items
                            for(let item of items){
                                item.toggle()
                            }

                            this.fetch()

                        }else{
                            this.saveColor = 'error'
                            this.saveText = data.message
                        }

                    }).catch(() => {
                        key.loadDel = false 
                    })
                })
            }
        }
    }

</script>